import React, { memo } from 'react';
import Link from 'next/link';
import Widget from '@views/components/widget/Widget';
import HorizontalCarousel from '@views/components/carousels/HorizontalCarousel';
import LazyImage, { LazyLoadImage } from '@views/components/lazy-image';
import Placeholder from './placeholder';

import { List } from 'immutable';
import { ICategory } from '@server/data/models/category';
import { useCategoryList } from './hooks/use-category';
import { INTERNAL_URI, categoryHref } from '@helpers/route';
import _chunk from '@helpers/immutable-list-chunk';
import truncate from 'lodash/truncate';

import cn from 'classnames';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';
import { absoluteUrl } from '@lib/helpers';

const CategoryList = memo<{ data: any }>(({ data }) => (
  <HorizontalCarousel id="__categoryList__" contentClassName="widget__group">
    {_chunk(data, 1).map((items: List<ICategory>, rootIndex) => (
      <Widget.GroupItem
        key={`category-root-${items.getIn([0, 'id']) || rootIndex}`}
      >
        {items.map(
          (item: any): JSX.Element => (
            <Link
              key={`category-item-${item.get('id')}`}
              href={INTERNAL_URI.CATEGORY}
              as={categoryHref(item.get('id'), item.get('urlKey'))}
            >
              <a
                aria-label={item.get('name')}
                title={item.get('name')}
                className={cn(EVENT_CLASS_NAME)}
                data-category={EVENT_CATEGORY.CATEGORY_HOMEPAGE}
                data-action={EVENT_ACTION_NAME}
                data-label={item.get('name')}
              >
                <LazyImage
                  src={
                    item.get('imageIcon') ||
                    absoluteUrl('/assets/art-and-design.svg')
                  }
                  alt={item.get('name')}
                  className="widget__image widget__image--v2"
                  placeholder={absoluteUrl('/assets/art-and-design.svg')}
                />
                {/* <LazyLoadImage
                  image={{
                    placeholder: '/assets/art-and-design.svg',
                    src: item.get('imageIcon'),
                    alt: item.get('name'),
                    className: 'widget__image widget__image--v2',
                  }}
                /> */}
                <span className="text-center">
                  {truncate(item.get('name'), { length: 40 })}
                </span>
              </a>
            </Link>
          )
        )}
        {/* {items.size < 2 && <a key="category-item-not"></a>} */}
      </Widget.GroupItem>
    ))}
  </HorizontalCarousel>
));
CategoryList.displayName = 'CategoryList';

export const Container = memo(() => {
  const { loading, error, categories } = useCategoryList();

  if (loading || error) {
    return <Placeholder />;
  }

  return (
    <Widget grid brand>
      <Widget.Title as="h2" style={{ color: 'var(--gray-dark)' }}>
        DANH MỤC VĂN PHÒNG PHẨM
      </Widget.Title>
      <CategoryList data={categories} />
    </Widget>
  );
});
Container.displayName = 'CategoryListContainer';

export default Container;

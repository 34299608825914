import React, { memo } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import Widget from '@views/components/widget/Widget';
import HorizontalCarousel from '@views/components/carousels/HorizontalCarousel';
import LazyImage, { LazyLoadImage } from '@views/components/lazy-image';
import { absoluteUrl } from '@lib/helpers';

import cn from 'classnames';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';

interface PartnerProps {
  key: string;
  name: string;
  image: string;
  link?: string;
}

const Block = styled.div``;
const Heading = styled(Widget.Title)`
  text-align: center;
  font-size: 1.5rem;
`;
const Item = styled.div`
  flex: 0 0 150px;
  width: 150px;
  height: 120px;
  list-style: none;
  display: inline-block;
  overflow: hidden;

  a {
    padding: 8px;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    color: var(--black);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--gray-light);
    border-bottom: 1px solid var(--gray-light);
    border-left: 1px solid var(--gray-light);
    overflow: hidden;
  }

  &:last-child {
    a {
      border-right: 1px solid var(--gray-light);
    }
  }
`;

const PARTNER_LIST: PartnerProps[] = [
  {
    key: 'double-a',
    name: 'Double A',
    image: absoluteUrl('/assets/partner/double-a@3x.png'),
    link: 'https://www.doubleapaper.com/',
  },
  {
    key: 'thien-long',
    name: 'Thiên Long',
    image: absoluteUrl('/assets/partner/thienlong@3x.png'),
    link: 'https://thienlonggroup.com/',
  },
  {
    key: 'ikplus',
    name: 'IKPlus',
    image: absoluteUrl('/assets/partner/ikplus.svg'),
    link: 'https://ikpluspaper.com/',
  },
  {
    key: 'deli',
    name: 'Deli',
    image: absoluteUrl('/assets/partner/deli.png'),
  },
  {
    key: 'kingjim',
    name: 'Kingjim',
    image: absoluteUrl('/assets/partner/kingjim.png'),
    link: 'https://kingjim.com.vn/',
  },
  {
    key: 'baoke',
    name: 'baoke',
    image: absoluteUrl('/assets/partner/baoke.jpeg'),
    link: 'http://www.baokepen.com/en/',
  },
  {
    key: 'casio',
    name: 'Casio',
    image: absoluteUrl('/assets/partner/casio.svg'),
    link: 'https://www.casio.com/',
  },
  {
    key: 'unilever',
    name: 'Unilever',
    image: absoluteUrl('/assets/partner/unilever@3x.png'),
    link: 'https://www.unilever.com.vn/',
  },
];

const PartnerComponent = () => {
  return (
    <Block>
      <Widget grid brand>
        <Heading as="h2">ĐỐI TÁC CỦA CHÚNG TÔI</Heading>
        <HorizontalCarousel id="_partner_" contentClassName="widget__group">
          {PARTNER_LIST.map((item: PartnerProps, index) => (
            <Item key={`partner-root-${get(item, 'key', index)}`}>
              <a
                href={get(item, 'link', '#')}
                className={cn(EVENT_CLASS_NAME)}
                data-category={EVENT_CATEGORY.LOYALTY_CUSTOMER}
                data-action={EVENT_ACTION_NAME}
                data-label={get(item, 'name')}
              >
                {/* <LazyImage
                  src={get(item, 'image')}
                  alt={get(item, 'name')}
                  className="widget__image"
                /> */}

                {/* <LazyLoadImage
                  image={{
                    src: get(item, 'image'),
                    alt: get(item, 'name'),
                    className: 'widget__image',
                  }}
                  wrapperStyle={{ width: '100%' }}
                  style={{ width: '100%' }}
                /> */}
                <img
                  src={get(item, 'image')}
                  alt={get(item, 'name')}
                  className="widget__image"
                  loading="lazy"
                />
              </a>
            </Item>
          ))}
        </HorizontalCarousel>
      </Widget>
    </Block>
  );
};

const Container = memo(PartnerComponent);
Container.displayName = 'PartnerComponent';

export default Container;

import styled from 'styled-components';
import { BlockTitle } from '@views/components/block/Block';

export const Wrapper = styled.div`
  .block__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  .swiper-slide {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
  }
  .swiper-block .swiper-container {
    background: #fff;
  }
`;

export const Title = styled(BlockTitle)`
  display: flex;
  align-items: center;
  img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    margin-right: 12px;
  }
`;
export const Item = styled.div`
  padding-bottom: 0.5rem;

  & > a {
    display: grid;
    grid-template-columns: 1fr 80px;
    .text {
      padding: 1rem 0.625rem;
      .keyword {
        overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        color: var(--black);
        font-weight: 500;
        text-transform: capitalize;
        font-size: 14px;
        display: block;
      }
      .note {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-top: 0.3125rem;
        color: #757575;
        font-size: 0.8125rem;
        display: block;
      }
    }
    .image {
      padding: 0.5rem;
      margin: auto;

      img {
        max-width: 100%;
        height: 80px;
        object-fit: contain;
      }
    }
  }
`;

import React from 'react';
import { Waypoint } from 'react-waypoint';

function LazyLoad({
  component: Component,
  placeholder: Placeholder = null,
  topOffset = -50,
  bottomOffset = -50,
  height = 200,
  fireOnRapidScroll = false,
  ...props
}) {
  const [isInteracted, setInteracted] = React.useState(false);
  const onEnterViewPort = React.useCallback(() => setInteracted(true), []);

  if (typeof window === undefined) {
    return null;
  }

  if (isInteracted) {
    return <Component {...props} />;
  }

  return (
    <>
      <Waypoint
        onEnter={onEnterViewPort}
        topOffset={topOffset}
        bottomOffset={bottomOffset}
        fireOnRapidScroll={fireOnRapidScroll}
      />
      {!!Placeholder && <Placeholder />}
    </>
  );
}

export default LazyLoad;

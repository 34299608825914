import styled from 'styled-components';
import Block from '@views/components/block/Block';

export const BannerWrapper = styled.div`
  img {
    border-radius: 4px;
    width: 100%;
  }
`;

export const BlockTitle = styled.h1`
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  padding: 0 1.425rem;
  display: flex;
  align-items: center;
  color: var(--gray-dark);
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
`;

export const BlockHeader = styled(Block.Header)`
  border: 1px solid var(--gray-light);
  border-radius: var(--radius) var(--radius) 0 0;
`;

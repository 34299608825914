import { FunctionComponent } from 'react';
import LazyLoadComponent from '@lib/lazy-component';
import Component from '.';
import Placeholder from './placeholder';

export const LazyComponent: FunctionComponent = (props) => (
  <LazyLoadComponent
    {...props}
    component={Component}
    placeholder={Placeholder}
  />
);

export default LazyComponent;

import React, { FunctionComponent } from 'react';
import Widget from '@views/components/widget/Widget';
import _times from 'lodash/times';

export const Placeholder: FunctionComponent<any> = () => (
  <Widget grid brand>
    <Widget.Title as="h1" style={{ color: 'var(--gray-dark)' }}>
      DANH MỤC
    </Widget.Title>
    <Widget.Group>
      <Widget.GroupItem />
    </Widget.Group>
  </Widget>
);

export default Placeholder;

import React from 'react';
import { NextSeo, SocialProfileJsonLd } from 'next-seo';
import { socialJson, homepageTitle } from 'next-seo.config';

import MainLayout from '@views/layouts/main-layout';
import Home from '@views/modules/home';
import BeforeBody from '@views/modules/home/sub-modules/before-body';
import AfterBody from '@views/modules/home/sub-modules/after-body';
import { HOST } from '@lib/helpers';

export const HomePage = () => {
  return (
    <MainLayout beforeBody={<BeforeBody />} afterBody={<AfterBody />}>
      <NextSeo title={homepageTitle} canonical={HOST} />
      <SocialProfileJsonLd {...socialJson} />
      <Home />
    </MainLayout>
  );
};

export default HomePage;

import React, { FunctionComponent } from 'react';
import ProductComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export const WidgetFilterList: FunctionComponent<TParentComponentProps> = (
  props
) => <ProductComponent {...props} />;
WidgetFilterList.defaultProps = {
  defaultClassName: 'widget-filter__list',
};

export default WidgetFilterList;

import React, { Component } from 'react';
import classNames from 'classnames';
import ProductComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';
import WidgetTitle from './WidgetTitle';
import WidgetGroup from './WidgetGroup';
import WidgetGroupItem from './WidgetGroupItem';
import WidgetFilter from './WidgetFilter';

export type TWidgetProps = TParentComponentProps & {
  loadmore?: boolean;
  horizontal?: boolean;
  features?: boolean;
  grid?: boolean;
  brand?: boolean;
};

export class Widget extends Component<TWidgetProps> {
  static defaultProps = {
    as: 'section',
    defaultClassName: 'widget',
    loadmore: false,
    horizontal: false,
    features: false,
    grid: false,
    brand: false,
  };

  static Title = WidgetTitle;
  static Group = WidgetGroup;
  static GroupItem = WidgetGroupItem;
  static Filter = WidgetFilter;

  render(): JSX.Element {
    const {
      children,
      className,
      loadmore,
      horizontal,
      features,
      grid,
      brand,
      ...otherProps
    } = this.props;

    const widgetClassName = classNames({
      'widget--load-more': loadmore,
      'widget--horizontal': horizontal,
      'widget--features': features,
      'widget--grid': grid,
      'widget--brand': brand,
      [className]: !!className,
    });

    return (
      <ProductComponent className={widgetClassName} {...otherProps}>
        {children}
      </ProductComponent>
    );
  }
}

export default Widget;
export { WidgetGroup, WidgetTitle, WidgetFilter };

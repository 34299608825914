import React from 'react';
import { stringify } from 'query-string';
import {
  useSearchSuggestKeywordsQuery,
  SuggestKeyword,
} from 'src/generated/graphql';

import { Block } from '@views/components/block/Block';
import { LazyImage } from '@views/components/lazy-image';
import get from 'lodash/get';
import { INTERNAL_ROUTES as ROUTES } from '@helpers/route';

// Import Swiper React components
import SwiperCore, {
  Navigation,
  Pagination,
  Virtual,
  A11y,
  Mousewheel,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import useBreakpoint, { Breakpoints } from '@hooks/use-break-point';
import cn from 'classnames';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';
import * as S from './styles';

// install Virtual module
SwiperCore.use([Navigation, Pagination, Virtual, A11y, Mousewheel]);

const SuggestKeywords: React.FC<any> = () => {
  const { data: suggestKeywords } = useSearchSuggestKeywordsQuery({
    variables: {
      filter: {
        keyword: '',
        status: 1,
        page: 1,
        limit: 24,
      },
    },
    ssr: false,
  });
  const breakpoint = useBreakpoint();
  const isMobile = [Breakpoints.xs, Breakpoints.sm].includes(breakpoint);

  if (!suggestKeywords) {
    return null;
  }

  return (
    <S.Wrapper>
      <Block isSection>
        <Block.Header className="pb-4">
          <Block.Title black as="h4">
            <S.Title>
              <img
                src="/assets/icon-trending.png"
                className="item-icon"
                width={24}
                height={24}
              />
              <div>TÌM KIẾM PHỔ BIẾN</div>
            </S.Title>
          </Block.Title>
        </Block.Header>

        <div className="swiper-block">
          <Swiper
            spaceBetween={2}
            navigation={!isMobile}
            pagination
            // passiveListeners
            breakpoints={{
              375: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                navigation: false,
              },
              567: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                navigation: false,
              },
              768: {
                slidesPerView: 4,
                slidesPerGroup: 4,
              },
              992: {
                slidesPerView: 5,
                slidesPerGroup: 5,
              },
            }}
            autoHeight
          >
            {suggestKeywords.data.map((item, index: number) => {
              const encode = encodeURI(
                stringify(
                  {
                    q: item.keyword,
                  },
                  { encode: true }
                )
              );

              const url =
                item.url &&
                (item.url.includes('https://') || item.url.includes('http://'))
                  ? item.url
                  : `${ROUTES.SEARCH}?${encode}`;

              return (
                <SwiperSlide key={`${item.id}}_${index}`}>
                  <S.Item>
                    <a
                      key={index}
                      href={url}
                      aria-label={item.keyword}
                      title={item.keyword}
                      className={cn('search-item', EVENT_CLASS_NAME)}
                      data-category={EVENT_CATEGORY.TOP_SUGGEST_KEYWORD}
                      data-action={EVENT_ACTION_NAME}
                      data-label={item.keyword}
                    >
                      <div className="text">
                        <span className="keyword">{item.keyword}</span>
                        {item.note && <span className="note">{item.note}</span>}
                      </div>
                      <div className="image">
                        <LazyImage
                          src={item.image || '/assets/icon-search.png'}
                          alt={item.keyword}
                          className="item-icon"
                        />
                      </div>
                    </a>
                  </S.Item>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </Block>
    </S.Wrapper>
  );
};

export default SuggestKeywords;

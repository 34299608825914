import React, { FunctionComponent, MouseEvent } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
export interface IButton {
  isPrev?: boolean;
  isNext?: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const cx = (isPrev = false, isNext = false): string =>
  classNames({
    'vpp-carousel__prev': isPrev,
    'vpp-carousel__next': isNext,
  });

const icon =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHZpZXdCb3g9IjAgMCA0OCA0OCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48ZmlsdGVyIGlkPSJhIiB4PSIwIiB5PSIwIiB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PGZlT2Zmc2V0IGR5PSIzIiBpbnB1dD0iU291cmNlQWxwaGEiLz48ZmVHYXVzc2lhbkJsdXIgcmVzdWx0PSJiIiBzdGREZXZpYXRpb249IjMiLz48ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIuMTYxIi8+PGZlQ29tcG9zaXRlIGluMj0iYiIgb3BlcmF0b3I9ImluIi8+PGZlQ29tcG9zaXRlIGluPSJTb3VyY2VHcmFwaGljIi8+PC9maWx0ZXI+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNTI3IC0yMDApIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxNTI3IDIwMCkiIGZpbHRlcj0idXJsKCNhKSI+PGNpcmNsZSB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5IDYpIiBjeD0iMTUiIGN5PSIxNSIgcj0iMTUiIGZpbGw9IiNmZmYiLz48L2c+PHBhdGggdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTU1NiAyMTUpIHJvdGF0ZSg5MCkiIGQ9Ik02LDBsNiw5SDBaIiBmaWxsPSIjMzQzNDM0Ii8+PC9nPjwvc3ZnPg==';
const IconButton = styled.span`
  background: url(${icon}) center center no-repeat;
  background-size: 100%;
`;

export const Button: FunctionComponent<IButton> = ({
  isPrev = false,
  isNext = false,
  onClick,
}) => (
  <button
    className={cx(isPrev, isNext)}
    onClick={onClick}
    aria-label="carousel-navigation"
  >
    <IconButton className="vpp-carousel__icon" />

    {/* <svg width="48" height="48" viewBox="0 0 48 48" className="vpp-carousel__icon">
      <defs>
        <filter id="a" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse">
          <feOffset dy="3"/>
          <feGaussianBlur stdDeviation="3" result="b"/>
          <feFlood floodOpacity="0.161"/>
          <feComposite operator="in" in2="b"/>
          <feComposite in="SourceGraphic"/>
        </filter>
      </defs>
      <g transform="translate(-1527 -200)">
        <g transform="matrix(1, 0, 0, 1, 1527, 200)" filter="url(#a)">
          <circle cx="15" cy="15" r="15" transform="translate(9 6)" fill="#fff"/>
        </g>
        <path d="M6,0l6,9H0Z" transform="translate(1556 215) rotate(90)" fill="#343434"/>
      </g>
    </svg> */}
    {/* <img src="/assets/icon/icon-play.svg" className="vpp-carousel__icon"/> */}
  </button>
);

export default Button;

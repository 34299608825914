import React, { Component } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';
import classNames from 'classnames';
import Wrapper from './Wrapper';
import BannerItem from './BannerItem';

export type TBannerProps = TParentComponentProps & {
  version?: 0 | 1;
};
export class Banner extends Component<TBannerProps> {
  static defaultProps = {
    defaultClassName: 'banner',
    version: 0,
  };
  static Wrapper = Wrapper;
  static Item = BannerItem;

  render(): JSX.Element {
    const { className, version, ...otherProps } = this.props;
    const cx = classNames({
      'banner--version1': version === 1,
      [className]: !!className,
    });

    return <ParentComponent className={cx} {...otherProps} />;
  }
}

export default Banner;

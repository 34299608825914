import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { fromJS, List } from 'immutable';

import FragmentCategoryItem from '@graphql/fragments/category-list.gql';

const QUERY = gql`
  query CategoryPageQuery(
    $page: Int
    $limit: Int
    $orderBy: String
    $parentId: Int
  ) {
    categoryFilterQuery(
      page: $page
      limit: $limit
      orderBy: $orderBy
      parentId: $parentId
    ) {
      ...CategoryItem
    }
  }
  ${FragmentCategoryItem}
`;

interface Variables {
  page?: number;
  limit?: number;
  parentId?: number;
  orderBy?: string;
}

const defaultVariables: Variables = {
  page: 1,
  parentId: 1,
  limit: 200,
  orderBy: 'position_on_menu|asc',
};

export const useCategoryList = (
  variables: Variables = defaultVariables,
  ssr: boolean = false
) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables,
    ssr,
  });
  const categories = !!data ? fromJS(data.categoryFilterQuery) : List();

  return {
    categories,
    loading,
    error: !!error,
  };
};

export default useCategoryList;

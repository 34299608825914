import React, { Component } from 'react';
import ProductComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';
import WidgetFilterList from './WidgetFilterList';

export class WidgetFilter extends Component<TParentComponentProps> {
  static defaultProps = {
    defaultClassName: 'widget-filter',
  };
  static List = WidgetFilterList;

  render(): JSX.Element {
    return <ProductComponent {...this.props} />;
  }
}

export default WidgetFilter;

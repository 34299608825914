import React, { memo } from 'react';

// Import Swiper React components
import SwiperCore, { Navigation, Pagination, Virtual, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// install Virtual module
SwiperCore.use([Navigation, Pagination, Virtual, A11y]);

import useBreakpoint, { Breakpoints } from '@hooks/use-break-point';
import _get from 'lodash/get';
import ProductCard from '@views/modules/product-card';

type Props = {
  products: any;
  spaceBetween?: number;
  // slidesPerView?: number;
  navigation?: boolean;
  mousewheel?: boolean;
  pagination?: boolean;
  breakpoints?: any;
  dataCategory?: string;
  dataAction?: string;
  dataLabel?: string;
  dataClassName?: string;
};

export const ProductSwiper = (props: Props) => {
  const {
    products,
    // slidesPerView = 6,
    spaceBetween = 1,
    navigation = true,
    mousewheel = false,
    pagination = true,
    breakpoints: breakpointProps,
    dataCategory,
    dataAction,
    dataLabel,
    dataClassName,
  } = props;
  const breakpoint = useBreakpoint();
  const isMobile = [Breakpoints.xs, Breakpoints.sm].includes(breakpoint);

  return (
    <div className="swiper-block">
      <Swiper
        spaceBetween={spaceBetween}
        navigation={!isMobile && navigation}
        pagination={pagination}
        mousewheel={mousewheel}
        breakpoints={
          breakpointProps || {
            375: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              navigation: false,
            },
            567: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              navigation: false,
            },
            768: {
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
            992: {
              slidesPerView: 6,
              slidesPerGroup: 4,
            },
          }
        }
        autoHeight
      >
        {products.map((item: any, index: number) => (
          <SwiperSlide key={`${item.get('productId')}_${index}`}>
            <ProductCard
              id={_get(item, 'productId')}
              name={_get(item, 'name')}
              image={_get(item, 'imageUrl')}
              listPrice={
                _get(item, 'attributes.listPrice') || _get(item, 'price')
              }
              price={_get(item, 'price')}
              quantityAvailable={_get(item, 'quantity', 0)}
              hasVideo={_get(item, 'hasVideo', false)}
              isContactProduct={_get(item, 'isContactProduct', false)}
              isInterested={_get(item, 'isInterested', false)}
              dataAction={dataAction}
              dataCategory={dataCategory}
              dataClassName={dataClassName}
              dataLabel={_get(item, 'name') || dataLabel}
              nofollow={!_get(item, 'attributes.googleIndex', true)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default memo(ProductSwiper);

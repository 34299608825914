import React, { FunctionComponent } from 'react';
import ProductComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

export const WidgetTitle: FunctionComponent<TParentComponentProps> = (
  props
) => <ProductComponent {...props} />;
WidgetTitle.defaultProps = {
  defaultClassName: 'widget__title',
};

export default WidgetTitle;

import Immutable, { List } from 'immutable';

export default function splitIntoChunks(
  list: List<any>,
  chunkSize: number = 1
) {
  return Immutable.Range(0, list.count(), chunkSize).map((chunkStart) =>
    list.slice(chunkStart, chunkStart + chunkSize)
  );
}

import React, {
  useCallback,
  useRef,
  PropsWithChildren,
  FunctionComponent,
  ElementType,
  useEffect,
} from 'react';
import Button from './Button';
import cn from 'classnames';

export const SETTINGS = {
  navBarTravelling: false,
  navBarTravelDirection: '',
  navBarTravelDistance: 250,
};

const determineOverflow = (content: HTMLElement, container: HTMLElement) => {
  let containerMetrics = container.getBoundingClientRect();
  let containerMetricsRight = Math.floor(containerMetrics.right);
  let containerMetricsLeft = Math.floor(containerMetrics.left);
  let contentMetrics = content.getBoundingClientRect();
  let contentMetricsRight = Math.floor(contentMetrics.right);
  let contentMetricsLeft = Math.floor(contentMetrics.left);
  if (
    containerMetricsLeft > contentMetricsLeft &&
    containerMetricsRight < contentMetricsRight
  ) {
    return 'both';
  }
  if (contentMetricsLeft < containerMetricsLeft) {
    return 'left';
  }
  if (contentMetricsRight > containerMetricsRight) {
    return 'right';
  }

  return 'none';
};

export type HorizontalCarouselProps = PropsWithChildren<{
  id: string;
  wrapperClassName?: string;
  contentClassName?: string;
  as?: ElementType;
}>;

export const HorizontalCarousel: FunctionComponent<HorizontalCarouselProps> = ({
  id,
  wrapperClassName,
  contentClassName,
  children,
  as: Component = 'div',
}) => {
  const wrapperRef = useRef(null);
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  const className = cn({
    [wrapperClassName]: !!wrapperClassName,
    'vpp-carousel__wrapper': true,
    js: true,
  });

  const classNameOfContent = cn({
    [contentClassName]: !!contentClassName,
    'vpp-carousel__content': true,
  });
  // Handle the scroll of the horizontal container
  let lastKnownScrollPosition = 0;
  let ticking = false;
  let reqAnimationFrame = null;

  useEffect(() => {
    containerRef.current.setAttribute(
      'data-overflowing',
      determineOverflow(contentRef.current, containerRef.current)
    );
  }, []);

  useEffect(() => {
    const handler = (event) => {
      lastKnownScrollPosition = window.scrollY;
      if (!ticking) {
        reqAnimationFrame = window.requestAnimationFrame(function () {
          containerRef.current.setAttribute(
            'data-overflowing',
            determineOverflow(contentRef.current, containerRef.current)
          );
          ticking = false;
        });
      }
      ticking = true;
    };

    containerRef.current.addEventListener('scroll', handler);

    // clean up
    return () => {
      containerRef.current.removeEventListener('scroll', handler);
      window.cancelAnimationFrame(reqAnimationFrame);
    };
  }, [containerRef]); // empty array => run only once

  useEffect(() => {
    const handler = (event) => {
      // get the value of the transform, apply that to the current scroll position (so get the scroll pos first) and then remove the transform
      let styleOfTransform = window.getComputedStyle(contentRef.current, null);
      let tr =
        styleOfTransform.getPropertyValue('-webkit-transform') ||
        styleOfTransform.getPropertyValue('transform');
      // If there is no transition we want to default to 0 and not null
      let amount = Math.abs(parseInt(tr.split(',')[4]) || 0);
      contentRef.current.style.transform = 'none';
      contentRef.current.classList.add('no-transition');
      // Now lets set the scroll position
      if (SETTINGS.navBarTravelDirection === 'left') {
        containerRef.current.scrollLeft =
          containerRef.current.scrollLeft - amount;
      } else {
        containerRef.current.scrollLeft =
          containerRef.current.scrollLeft + amount;
      }
      SETTINGS.navBarTravelling = false;
    };

    contentRef.current.addEventListener('transitionend', handler, false);

    // clean up
    return () =>
      contentRef.current.removeEventListener('transitionend', handler);
  }, [contentRef]); // empty array => run only once

  const handlePrevClick = useCallback(
    (event) => {
      // If in the middle of a move return
      if (SETTINGS.navBarTravelling === true) {
        return;
      }
      const determine = determineOverflow(
        contentRef.current,
        containerRef.current
      );

      // If we have content overflowing both sides or on the left
      if (determine === 'left' || determine === 'both') {
        // Find how far this panel has been scrolled
        const availableScrollLeft = containerRef.current.scrollLeft;
        // If the space available is less than two lots of our desired distance, just move the whole amount
        // otherwise, move by the amount in the settings
        if (availableScrollLeft < SETTINGS.navBarTravelDistance * 2) {
          contentRef.current.style.transform =
            'translateX(' + availableScrollLeft + 'px)';
        } else {
          contentRef.current.style.transform =
            'translateX(' + SETTINGS.navBarTravelDistance + 'px)';
        }
        // We do want a transition (this is set in CSS) when moving so remove the class that would prevent that
        contentRef.current.classList.remove('no-transition');
        // Update our settings
        SETTINGS.navBarTravelDirection = 'left';
        SETTINGS.navBarTravelling = true;
      }
      // Now update the attribute in the DOM
      containerRef.current.setAttribute('data-overflowing', determine);
    },
    [contentRef, contentRef]
  );

  const handleNextClick = useCallback(
    (event) => {
      // If in the middle of a move return
      if (SETTINGS.navBarTravelling === true) {
        return;
      }
      const determine = determineOverflow(
        contentRef.current,
        containerRef.current
      );

      // If we have content overflowing both sides or on the right
      if (determine === 'right' || determine === 'both') {
        // Get the right edge of the container and content
        let navBarRightEdge = contentRef.current.getBoundingClientRect().right;
        let navBarScrollerRightEdge = containerRef.current.getBoundingClientRect()
          .right;
        // Now we know how much space we have available to scroll
        let availableScrollRight = Math.floor(
          navBarRightEdge - navBarScrollerRightEdge
        );
        // If the space available is less than two lots of our desired distance, just move the whole amount
        // otherwise, move by the amount in the settings
        if (availableScrollRight < SETTINGS.navBarTravelDistance * 2) {
          contentRef.current.style.transform =
            'translateX(-' + availableScrollRight + 'px)';
        } else {
          contentRef.current.style.transform =
            'translateX(-' + SETTINGS.navBarTravelDistance + 'px)';
        }
        // We do want a transition (this is set in CSS) when moving so remove the class that would prevent that
        contentRef.current.classList.remove('no-transition');
        // Update our settings
        SETTINGS.navBarTravelDirection = 'right';
        SETTINGS.navBarTravelling = true;
      }
      // Now update the attribute in the DOM
      containerRef.current.setAttribute('data-overflowing', determine);
    },
    [contentRef, contentRef]
  );

  return (
    <Component
      ref={wrapperRef}
      id={id}
      data-scroll="horizontal"
      className={className}
    >
      <div ref={containerRef} className="vpp-carousel__list">
        <div ref={contentRef} className={classNameOfContent}>
          {children}
        </div>
      </div>
      <Button isPrev onClick={handlePrevClick} />
      <Button isNext onClick={handleNextClick} />
    </Component>
  );
};

export default HorizontalCarousel;

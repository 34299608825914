import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import ProductComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';

type TWidgetGroupItemProps = TParentComponentProps & {
  v2?: boolean;
  v3?: boolean;
};

export const WidgetGroupItem: FunctionComponent<TWidgetGroupItemProps> = ({
  className,
  v2,
  v3,
  ...otherProps
}) => {
  const componentClass = classNames({
    'widget__group-item--v2': v2,
    'widget__group-item--v3': v3,
    [className]: !!className,
  });

  return <ProductComponent className={componentClass} {...otherProps} />;
};
WidgetGroupItem.defaultProps = {
  defaultClassName: 'widget__group-item',
  v2: false,
};

export default WidgetGroupItem;

import React, { memo } from 'react';

// Import Swiper React components
import SwiperCore, {
  Navigation,
  Pagination,
  Virtual,
  A11y,
  Mousewheel,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';

// install Virtual module
SwiperCore.use([Navigation, Pagination, Virtual, A11y, Mousewheel]);

import styled from 'styled-components';
import useBreakpoint, { Breakpoints } from '@hooks/use-break-point';
import _get from 'lodash/get';

import ProductCard from '@views/modules/product-card';

import cn from 'classnames';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';

type Props = {
  products: any[];
  spaceBetween?: number;
};

export const ProductSwiper = (props: Props) => {
  const { products, spaceBetween = 1 } = props;
  const breakpoint = useBreakpoint();
  const isMobile = [Breakpoints.xs, Breakpoints.sm].includes(breakpoint);

  return (
    <div className="swiper-block">
      <Swiper
        spaceBetween={spaceBetween}
        // slidesPerView={6}
        // mousewheel={!isMobile}
        navigation={!isMobile}
        pagination
        passiveListeners={!isMobile}
        breakpoints={{
          375: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            navigation: false,
          },
          567: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            navigation: false,
          },
          768: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
          992: {
            slidesPerView: 6,
            slidesPerGroup: 6,
          },
        }}
        autoHeight
      >
        {products.map((item: any, index: number) => (
          <SwiperSlide key={`${_get(item, 'id')}_${index}`}>
            <ProductCard
              id={parseInt(_get(item, 'id'))}
              name={_get(item, 'name')}
              image={`${process.env.CDN || ''}/${_get(item, 'image')}`}
              listPrice={
                _get(item, 'attributes.listPrice') || _get(item, 'price')
              }
              price={_get(item, 'price')}
              quantityAvailable={_get(item, 'quantity', 0)}
              hasVideo={_get(item, 'hasVideo', false)}
              isContactProduct={_get(item, 'isContactProduct', false)}
              isInterested={_get(item, 'isInterested', false)}
              dataClassName={EVENT_CLASS_NAME}
              dataAction={EVENT_ACTION_NAME}
              dataCategory={EVENT_CATEGORY.PRODUCT_HOMEPAGE}
              dataLabel={_get(item, 'name')}
              nofollow={!_get(item, 'attributes.googleIndex', true)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default memo(ProductSwiper);

import React, { memo } from 'react';
import SuggestKeywords from '@views/modules/suggest-keywords';

import CategoryList from './sub-modules/category';
import ProductCategory from './sub-modules/product-category/lazy';
// import ProductList from './sub-modules/product-list/lazy';
import RecentlyView from '@views/modules/recent-view/lazy';

const HomeComponent = () => {
  return (
    <>
      <CategoryList />
      <SuggestKeywords />
      <RecentlyView />
      <ProductCategory />
    </>
  );
};

const Home = memo(HomeComponent);
Home.displayName = 'Home';

export default Home;

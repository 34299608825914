import { LocalBusinessJsonLdProps, LogoJsonLdProps } from 'next-seo';
import { OpenGraph } from 'next-seo/lib/types';

export const name = 'CÔNG TY TNHH TM DV FAST';
export const address = '671/3 Hồng Bàng, Phường 6, Quận 6, TP.HCM';
export const telephone = '0964 399 099';
export const email = 'vanphongphamfast@gmail.com';
export const lang = 'vi';
export const geo = 'Viet Nam';
export const website = 'https://fast.vn';
export const siteName = 'fast.vn';
export const keywords = 'Fast.vn';
export const homepageTitle =
  'Văn Phòng Phẩm Online FAST - Tối Ưu Chi Phí Thời Bão Giá';
export const description =
  'VĂN PHÒNG PHẨM Giá Rẻ, Bao Giá Sài Gòn, Công Nợ 30 Ngày, Giao Hàng Nhanh, Đặt ít Vẫn Giao, Dịch vụ khắc dấu, Giấy in, Giấy A4 giá rẻ, Dịch Vụ văn phòng phẩm trọn gói, hỗ trợ đổi trả hàng văn phòng';
export const titleTemplate = `%s | ${keywords}`;
export const logoJSON: LogoJsonLdProps = {
  logo: `${website}/assets/logo/icon.png`,
  url: website,
};

export const localBusinessJSON: LocalBusinessJsonLdProps = {
  telephone,
  description,
  type: 'Store',
  id: siteName,
  name: 'Văn phòng phẩm Fast',
  address: {
    streetAddress: '671/3 Hồng Bàng, Phường 6, Quận 6, TP.HCM',
    addressLocality: 'TP.HCM',
    addressRegion: 'TP.HCM',
    postalCode: '7000',
    addressCountry: 'VN',
  },
  geo: {
    latitude: '10.756182',
    longitude: '106.6473882',
  },
  images: [
    '/assets/logo/icon.png',
    '/assets/banner01@2x.jpg',
    '/assets/banner02@2x.jpg',
    '/assets/banner03@2x.jpg',
    '/assets/banner04@2x.jpg',
    '/assets/banner-05-new.jpg',
  ],
  priceRange: '$',
};

export const socialJson = {
  type: 'Organization',
  name: 'Văn phòng phẩm FAST · Giấy in A4 · Dịch Vụ Khắc Dấu Uy Tín · Băng Keo',
  url: website,
  sameAs: ['https://www.facebook.com/vppfast'],
};

export default {
  titleTemplate,
  keywords,
  description,
  // title: keywords,
  defaultTitle: keywords,
  openGraph: {
    title: keywords,
    description,
    type: 'website',
    locale: 'vi_VN',
    url: website,
    site_name: siteName,
  } as OpenGraph,
  twitter: {
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image',
  },
};

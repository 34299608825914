export const formatCurrency = (
  number: number,
  fixed: number = 0,
  decimal: string = '.'
): string => {
  try {
    return number
      .toFixed(fixed)
      .replace('.', ',') // replace decimal point character with ,
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${decimal}`);
  } catch (ex) {}

  return '';
};

export const formatCurrencyVN = (
  number: number,
  fixed: number = 0,
  decimal: string = '.'
) => `${formatCurrency(number, fixed, decimal)} đ`;

import React, { FC, useCallback, useEffect } from 'react';
import Cookies from 'universal-cookie';
import Link from 'next/link';
import { INTERNAL_ROUTES } from '@helpers/route';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _take from 'lodash/take';

import cn from 'classnames';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';

import { useLayoutContext } from '@views/layouts';
import { Block } from '@views/components/block';
import ProductSwiper from '@views/modules/product-swiper';
import Placeholder from './placeholder';

import {
  useSearchProductByIdsQuery,
  useSearchProductByCustomerViewQuery,
} from 'src/generated/graphql';

import { get } from './lib/tracking';
const LIMIT = 6;

const ProductList = ({ products, breakpoints }) => {
  const length = products?.length || 0;
  const isLessProduct = length > LIMIT;

  // const handleImpression = useCallback(() => {
  //   if (products?.length > 0) {
  //     if (window.dataLayer) {
  //       dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  //       dataLayer.push({
  //         ecommerce: {
  //           currencyCode: 'VND', // Local currency is optional.
  //           impressions: _take(products, 6).map((item: any, index: number) => ({
  //             id: _get(item, 'id'),
  //             name: _get(item, 'name'),
  //             price: _get(item, 'price'),
  //             brand: '',
  //             category: '',
  //             list: 'Recently products view',
  //             position: index + 1,
  //           })),
  //         },
  //       });
  //     }
  //   }
  // }, [products?.length]);

  // useEffect(() => {
  //   handleImpression();
  // }, [products?.length]);

  return (
    <Block className="mt-4" as="aside">
      <Block.Header className="mb-4">
        <Block.Title as="h3">SẢN PHẨM BẠN ĐÃ XEM</Block.Title>
        <Link href={INTERNAL_ROUTES.RECENT_PRODUCT}>
          <a aria-label="Xem thêm" title="Xem thêm" className="block__link">
            Xem thêm
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="12"
              viewBox="0 0 10.5 14"
            >
              <path d="M7,0l7,10.5H0Z" transform="translate(10.5) rotate(90)" />
            </svg>
          </a>
        </Link>
      </Block.Header>

      <ProductSwiper
        products={products}
        navigation={isLessProduct}
        pagination={isLessProduct}
        mousewheel={isLessProduct}
        breakpoints={breakpoints}
        dataAction={EVENT_ACTION_NAME}
        dataClassName={EVENT_CLASS_NAME}
        dataCategory={EVENT_CATEGORY.PRODUCT_RECENTLY}
      />
    </Block>
  );
};

export const RecentlyProduct = ({
  isLoading,
  empty,
  products,
  breakpoints,
}) => {
  if (isLoading) {
    return <Placeholder />;
  }

  if (empty) {
    return null;
  }

  return <ProductList products={products} breakpoints={breakpoints} />;
};

type RecentlyProductUnLoggedUserProps = {
  ids: number[];
  breakpoints: any;
};

export const RecentlyProductUnLoggedUser: FC<RecentlyProductUnLoggedUserProps> = ({
  ids,
  breakpoints,
}) => {
  const { data, loading, error } = useSearchProductByIdsQuery({
    variables: {
      page: 1,
      limit: 24,
      status: 'all',
    },
  });

  const isLoading = loading || !!error;
  const empty = !!data?.searchProduct || ids.length === 0;

  return (
    <RecentlyProduct
      isLoading={isLoading}
      empty={empty}
      products={data?.searchProduct}
      breakpoints={breakpoints}
    />
  );
};

type RecentlyProductByCustomerProps = {
  customerId: number;
  breakpoints: any;
};

export const RecentlyProductByCustomer: FC<RecentlyProductByCustomerProps> = ({
  customerId,
  breakpoints,
}) => {
  const { data, loading, error } = useSearchProductByCustomerViewQuery({
    variables: {
      customerId: `${customerId}`,
      page: 1,
      limit: 24,
      status: 'all',
    },
  });
  const isLoading = loading || !!error;
  const empty = !!data?.searchProduct;

  return (
    <RecentlyProduct
      isLoading={isLoading}
      empty={empty}
      products={data?.searchProduct}
      breakpoints={breakpoints}
    />
  );
};

const Container: FC<{
  breakpoints?: any;
}> = ({ breakpoints }) => {
  const cookies = new Cookies();
  const ids = get(cookies) as Array<number>;
  const { me } = useLayoutContext();

  if (me.size > 0) {
    return (
      <RecentlyProductByCustomer
        customerId={parseInt(me.get('id'))}
        breakpoints={breakpoints}
      />
    );
  }

  if (ids.length === 0) {
    return null;
  }

  return <RecentlyProductUnLoggedUser ids={ids} breakpoints={breakpoints} />;
};

export default Container;

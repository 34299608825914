import React, { memo } from 'react';
import styled from 'styled-components';
// import chunk from 'lodash/chunk';
import get from 'lodash/get';

import Widget from '@views/components/widget/Widget';
import HorizontalCarousel from '@views/components/carousels/HorizontalCarousel';
import LazyImage, { LazyLoadImage } from '@views/components/lazy-image';
import { absoluteUrl } from '@lib/helpers';

import cn from 'classnames';
import {
  EVENT_CLASS_NAME,
  EVENT_ACTION_NAME,
  EVENT_CATEGORY,
  EVENT_LABEL,
} from '@helpers/events';

interface CustomerProps {
  key: string;
  name: string;
  image: string;
  link?: string;
}

const Block = styled.div``;
const Heading = styled(Widget.Title)`
  text-align: center;
  font-size: 1.5rem;
`;
const Item = styled.div`
  flex: 0 0 240px;
  width: 240px;
  height: 130px;
  list-style: none;
  display: inline-block;
  overflow: hidden;

  a {
    padding: 8px;
    width: 100%;
    height: 100%;
    font-size: 1rem;
    color: var(--black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--gray-light);
    border-left: 1px solid var(--gray-light);
    border-top: 1px solid var(--gray-light);
  }

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    height: 50px;
  }

  div {
    flex: 1 1 auto;
    max-width: 100%;
    margin-top: 1rem;
    font-weight: 500;
    white-space: normal;
    line-height: 1.2;
    text-align: center;
    color: var(--gray-600);
  }

  &:last-child {
    a {
      border-right: 1px solid var(--gray-light);
    }
  }
`;

const CUSTOMER_LIST: CustomerProps[] = [
  {
    key: 'trung-tam-y-te-quan-11',
    name: 'TRUNG TÂM Y TẾ QUẬN 11',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'http://trungtamytequan11.medinet.gov.vn/Default.aspx',
  },
  {
    key: 'cong-ty-tnhh-may-theu-giay-an-phuoc',
    name: 'CÔNG TY TNHH MAY THÊU GIÀY AN PHƯỚC',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'https://anphuocgroup.com/',
  },
  {
    key: 'cong-ty-tnhh-upandups-logistics',
    name: 'CÔNG TY TNHH UP&UPS LOGISTICS',
    image: absoluteUrl('/assets/customer-service.svg'),
  },
  {
    key:
      'ban-quan-ly-du-an-djau-tu-xay-dung-cac-cong-trinh-dan-dung-va-cong-nghiep',
    name:
      'BAN QUẢN LÝ DỰ ÁN ĐẦU TƯ XÂY DỰNG CÁC CÔNG TRÌNH DÂN DỤNG VÀ CÔNG NGHIỆP',
    image: absoluteUrl('/assets/customer-service.svg'),
  },
  {
    key: 'CÔNG TY TNHH SAIGON CO-OP FAIRPRICE',
    name: 'CÔNG TY TNHH SAIGON CO-OP FAIRPRICE',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'http://www.saigonco-op.com.vn/linhvuchoatdong/banle/bn-l_1104.html',
  },
  {
    key: 'ngan-hang-malayan-banking-berhad-chi-nhanh-thanh-pho-ho-chi-minh',
    name: 'NGÂN HÀNG MALAYAN BANKING BERHAD - CHI NHÁNH THÀNH PHỐ HỒ CHÍ MINH',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'https://www.maybank.com',
  },
  {
    key: 'cong-ty-bao-hiem-pvi-gia-djinh',
    name: 'CÔNG TY BẢO HIỂM PVI GIA ĐỊNH',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'http://www.baohiempvi.com.vn/',
  },
  {
    key: 'cong-ty-tnhh-savills-viet-nam-chi-nhanh-thanh-pho-ho-chi-minh',
    name: 'CÔNG TY TNHH SAVILLS (VIỆT NAM ) - CHI NHÁNH THÀNH PHỐ HỒ CHÍ MINH',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'https://vn.savills.com.vn/',
  },
  {
    key: 'cong-ty-tnhh-routine-viet-nam',
    name: 'CÔNG TY TNHH ROUTINE VIỆT NAM',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'https://routine.vn/',
  },
  {
    key: 'cong-ty-co-phan-cang-va-dich-vu-logistics-vrg-thanh-phuoc',
    name: 'CÔNG TY CỔ PHẦN CẢNG VÀ DỊCH VỤ LOGISTICS VRG THANH PHƯỚC',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'https://thanhphuocport.com/',
  },
  {
    key: 'chi-nhanh-thanh-pho-ho-chi-minh-cong-ty-tnhh-kiem-toan-vaco',
    name: 'CHI NHÁNH THÀNH PHỐ HỒ CHÍ MINH - CÔNG TY TNHH KIỂM TOÁN VACO',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'http://www.vaco.com.vn/',
  },
  {
    key: 'ngan-hang-thuong-mai-co-phan-viet-a',
    name: 'NGÂN HÀNG THƯƠNG MẠI CỔ PHẦN VIỆT Á',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'https://www.vietacorp.com/',
  },
  {
    key: 'ngan-hang-thuong-mai-co-phan-phat-trien-tp-ho-chi-minh',
    name: 'NGÂN HÀNG THƯƠNG MẠI CỔ PHẦN PHÁT TRIỂN TP HỒ CHÍ MINH',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'https://hdbank.com.vn/',
  },
  {
    key: 'ngan-hang-thuong-mai-co-phan-a-chau',
    name: 'NGÂN HÀNG THƯƠNG MẠI CỔ PHẦN Á CHÂU',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'https://www.acb.com.vn/',
  },
  {
    key: 'cong-ty-tnhh-phong-kham-dja-khoa-quoc-te',
    name: 'CÔNG TY TNHH PHÒNG KHÁM ĐA KHOA QUỐC TẾ',
    image: absoluteUrl('/assets/customer-service.svg'),
  },
  {
    key: 'truong-djai-hoc-khoa-hoc-xa-hoi-va-nhan-van',
    name: 'TRƯỜNG ĐẠI HỌC KHOA HỌC XÃ HỘI VÀ NHÂN VĂN',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'https://hcmussh.edu.vn/',
  },
  {
    key: 'van-phong-cong-chung-tran-thanh-hai',
    name: 'VĂN PHÒNG CÔNG CHỨNG TRẦN THANH HẢI',
    image: absoluteUrl('/assets/customer-service.svg'),
  },
  {
    key: 'cong-ty-co-phan-khoa-hoc-moi-truong-va-an-toan-lao-djong-mien-nam',
    name: 'CÔNG TY CỔ PHẦN KHOA HỌC MÔI TRƯỜNG VÀ AN TOÀN LAO ĐỘNG MIỀN NAM',
    image: absoluteUrl('/assets/customer-service.svg'),
  },
  {
    key: 'cong-ty-co-phan-tham-djinh-gia-va-tu-van-djau-tu-viet-nam',
    name: 'CÔNG TY CỔ PHẦN THẨM ĐỊNH GIÁ VÀ TƯ VẤN ĐẦU TƯ VIỆT NAM',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'http://vinap.vn/',
  },
  {
    key: 'cong-ty-co-phan-fecon-south',
    name: 'CÔNG TY CỔ PHẦN FECON SOUTH',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'https://www.fecon.com.vn/cong-ty-cp-fecon-south-u18/',
  },
  {
    key: 'cong-ty-co-phan-truyen-thong-quang-cao-buu-chinh',
    name: 'CÔNG TY CỔ PHẦN TRUYỀN THÔNG QUẢNG CÁO BƯU CHÍNH',
    image: absoluteUrl('/assets/customer-service.svg'),
  },

  {
    key: 'cong-ty-co-phan-nhanhvn',
    name: 'CÔNG TY CỔ PHẦN NHANH.VN',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'https://nhanh.vn/',
  },
  {
    key: 'benh-vien-mat-tphcm',
    name: 'BỆNH VIỆN MẮT TP.HCM',
    image: absoluteUrl('/assets/customer-service.svg'),
  },
  {
    key: 'benh-vien-cho-ray',
    name: 'BỆNH VIỆN CHỢ RẪY',
    image: absoluteUrl('/assets/customer-service.svg'),
    link: 'http://choray.vn/',
  },
];

// const data = chunk(CUSTOMER_LIST, 3);

const PartnerComponent = () => {
  return (
    <Block>
      <Widget grid brand>
        <Heading as="h2">KHÁCH HÀNG THÂN THIẾT</Heading>
        <HorizontalCarousel id="_customer_" contentClassName="widget__group">
          {CUSTOMER_LIST.map((item: CustomerProps, index) => (
            <Item key={`_customer_-root-${get(item, 'key', index)}`}>
              <a
                href={get(item, 'link', '#')}
                key={`_customer_-item-${get(item, 'key')}`}
                className={cn(EVENT_CLASS_NAME)}
                data-category={EVENT_CATEGORY.LOYALTY_CUSTOMER}
                data-action={EVENT_ACTION_NAME}
                data-label={get(item, 'name')}
              >
                {/* <LazyImage src={get(item, 'image')} alt={get(item, 'name')} /> */}
                <LazyLoadImage
                  image={{
                    src: get(item, 'image'),
                    alt: get(item, 'name'),
                    className: 'widget__image',
                  }}
                  wrapperStyle={{ width: '100%' }}
                />
                <div aria-label={get(item, 'name')} title={get(item, 'name')}>
                  {get(item, 'name')}
                </div>
              </a>
            </Item>
          ))}
        </HorizontalCarousel>
      </Widget>
    </Block>
  );
};

const Container = memo(PartnerComponent);
Container.displayName = 'PartnerComponent';

export default Container;

// import React, { FunctionComponent, memo } from 'react';
// import { ListGroup, ListGroupItem } from 'react-bootstrap';
// import { FEATURE_LINKS } from '@mocks/sample-data';
// import { Widget } from '@views/components/widget';
// import LazyImage from '@views/components/lazy-image';

// import cn from 'classnames';
// import {
//   EVENT_CLASS_NAME,
//   EVENT_ACTION_NAME,
//   EVENT_CATEGORY,
//   EVENT_LABEL,
// } from '@helpers/events';

// export const Features: FunctionComponent<any> = (): JSX.Element => (
//   <Widget horizontal features>
//     <ListGroup className="list-group-horizontal-lg widget__group">
//       {FEATURE_LINKS.map(({ key, text, imageSrc }, index: number) => (
//         <ListGroupItem
//           key={key || `widget-features-${index}`}
//           className={cn('widget__group-item', EVENT_CLASS_NAME)}
//           data-category={EVENT_CATEGORY.ACTION}
//           data-action={EVENT_ACTION_NAME}
//           data-label={EVENT_LABEL.FEATURES_HOMEPAGE_ICON}
//         >
//           <img src={imageSrc} className="widget__icon" alt={text} />
//           {text}
//         </ListGroupItem>
//       ))}
//     </ListGroup>
//   </Widget>
// );

export default () => <></>;

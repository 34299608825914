import React, { Component } from 'react';
import ParentComponent, {
  TParentComponentProps,
} from '@views/components/common/ParentComponent';
import classNames from 'classnames';

export type TBannerItemProps = TParentComponentProps & {
  version?: number;
};

export class BannerItem extends Component<TBannerItemProps> {
  static defaultProps = {
    defaultClassName: 'banner__item',
    version: -1,
  };

  render() {
    const { className, version, ...otherProps } = this.props;
    const cx = classNames({
      'banner__item--first': version === 0,
      'banner__item--second': version === 1,
      'banner__item--third': version === 2,
      'banner__item--last': version === 3,
      [className]: !!className,
    });

    return <ParentComponent className={cx} {...otherProps} />;
  }
}

export default BannerItem;

import React from 'react';
import {
  BlockProduct,
  BlockProductListSkeleton,
} from '@views/components/block/Block';
import useBreakpoint, { Breakpoints } from '@hooks/use-break-point';

export const Placeholder = () => {
  const breakpoint = useBreakpoint();
  const isMobile = [Breakpoints.xs, Breakpoints.sm].includes(breakpoint);

  return (
    <BlockProduct>
      <BlockProduct.Body>
        <BlockProduct.List>
          <BlockProductListSkeleton number={isMobile ? 2 : 6} />
        </BlockProduct.List>
      </BlockProduct.Body>
    </BlockProduct>
  );
};

export default Placeholder;

import React, { Fragment, memo } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import BannerComponent from '@views/components/banner/Banner';
import Link from 'next/link';
import LazyImage from '@views/components/lazy-image';
import { BANNER_ADS } from '@mocks/sample-data';
import _get from 'lodash/get';
import useBreakpoint, { Breakpoints } from '@hooks/use-break-point';

import { Banner, BannerMobile } from '@views/modules/home/sub-modules/banner';
import Features from '@views/modules/home/sub-modules/features';

const Wrapper = styled.div`
  background: #fff
    url(https://cf.shopee.vn/file/3d8d18abbf445264d2b06638653b364c) center top /
    100% no-repeat;
  margin-bottom: ${(props) => (props.isMobile ? `15px` : `0`)};
  padding: ${(props) => (props.isMobile ? `15px 0` : `30px 0 0`)};
`;

export const BeforeBody = () => {
  const breakpoint = useBreakpoint();
  const isMobile = [Breakpoints.xs, Breakpoints.sm].includes(breakpoint);
  // const style = {
  //   background: '#fff',
  //   // background:
  //   //   '#fff url("assets/background_tet.png") center top / 100% no-repeat',
  //   padding: '30px 0 0',
  // };

  // if (isMobile) {
  //   style['padding'] = '15px 0';
  //   style['margin'] = '0 0 15px';
  // }

  return (
    <>
      <Wrapper isMobile={isMobile}>
        <Container>
          {isMobile ? (
            <BannerMobile />
          ) : (
            <>
              <Banner />
              <Features />
            </>
          )}
        </Container>
      </Wrapper>

      {/* <Container className="mt-4">
        <Link
          href={_get(BANNER_ADS, 'url')}
          key={`banner-top-${_get(BANNER_ADS, 'key')}`}
          passHref
        >
          <BannerComponent.Item
            as="a"
            aria-label={_get(BANNER_ADS, 'alt')}
            title={_get(BANNER_ADS, 'alt')}
            version={3}
          >
            <LazyImage
              src={_get(BANNER_ADS, 'src')}
              srcSet={_get(BANNER_ADS, 'srcSet')}
              alt={_get(BANNER_ADS, 'alt')}
            />
          </BannerComponent.Item>
        </Link>
      </Container> */}
    </>
  );
};

export default memo(BeforeBody);
